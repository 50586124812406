import HttpRequest from "./http_request";
import * as moment from "moment";

export default class B2bProvider extends HttpRequest {
  async log(event, payload) {
    try {
      const response = await this.pushLog(event, payload);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async register(body) {
    try {
      const formData = new FormData();
      Object.keys(body).forEach((key) => {
        if (body[key]) {
          if (Array.isArray(body[key])) formData.append(key, JSON.stringify(body[key]));
          else formData.append(key, body[key]);
        }
      });

      const response = await this.post("/v1/auth/register", formData);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async staffRegister(body) {
    try {
      const response = await this.post("/v1/auth/register/staff", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async login(body) {
    try {
      const response = await this.post("/v1/auth/login", body);
      return { data: response?.data, error: null };
    } catch (err) {
      // if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async logout() {
    try {
      const response = await this.post("/v1/auth/logout");
      return { data: response?.data, error: null };
    } catch (err) {
      // if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async forgotPassword(body) {
    try {
      const response = await this.post("/v1/auth/forgot-password", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async resetPassword(body) {
    try {
      const response = await this.post("/v1/auth/reset-password", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async changePassword(body) {
    try {
      const response = await this.post("/v1/auth/change-password", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async refresh() {
    try {
      const response = await this.get("/v1/auth/refresh");
      return { data: response?.data, error: null };
    } catch (err) {
      // if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async validateAccessToken() {
    try {
      const response = await this.get("/v1/auth/validate-access-token");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async validateSignupToken(token) {
    try {
      const response = await this.get("/v1/auth/validate-signup-token/" + token);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async validateResetPasswordToken(token) {
    try {
      const response = await this.get("/v1/auth/validate-reset-password-token/" + token);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAdminInvitee() {
    try {
      const response = await this.get("/v1/auth/admin-invitee");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async sendAdminInvitee(body) {
    try {
      const response = await this.post("/v1/auth/admin-invitee", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async sendMakerInvitee(body) {
    try {
      const response = await this.post("/v1/auth/maker-invitee", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async removeInviteeById(id, forceDeleteUser) {
    try {
      const response = await this.delete(
        "/v1/auth/invitee/id/" + id + (forceDeleteUser ? "?forceDeleteUser=" + !!forceDeleteUser : ""),
      );
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async removeInviteeByEmail(email, forceDeleteUser) {
    try {
      const response = await this.delete(
        "/v1/auth/invitee/email/" + email + (forceDeleteUser ? "?forceDeleteUser=" + !!forceDeleteUser : ""),
      );
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAgentAdmin() {
    try {
      const response = await this.get("/v1/users/agent/admin");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAgentByOrganizationId(organizationId) {
    try {
      const response = await this.get("/v1/users/agent/" + organizationId);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAgentMakerByOrganizationId(organizationId) {
    try {
      const response = await this.get("/v1/users/agent/maker/" + organizationId);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCompany(organizationId) {
    try {
      const response = await this.get("/v1/organizations/" + organizationId);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCompanyIgnore404(organizationId) {
    try {
      const response = await this.get("/v1/organizations/" + organizationId + "/ignore-404");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async updateCompany(organizationId, body) {
    try {
      const formData = new FormData();
      Object.keys(body).forEach((key) => {
        if (body[key]) {
          if (Array.isArray(body[key])) formData.append(key, JSON.stringify(body[key]));
          else formData.append(key, body[key]);
        }
      });

      const response = await this.update("/v1/organizations/" + organizationId, formData);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async updateUser(userId, body) {
    try {
      const response = await this.update("/v1/users/" + userId, body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async rejectAgentAdmin(body) {
    try {
      const response = await this.post("/v1/organizations/reject", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async approveAgentAdmin(body) {
    try {
      const response = await this.post("/v1/organizations/approve", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getSearchOptions() {
    try {
      const response = await this.get("/v1/search/search-options");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCruiseItineraries(criteria) {
    try {
      const response = await this.post("/v1/search/cruise-itineraries", criteria);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCabins(body) {
    try {
      const response = await this.post("/v1/b2c/cabins", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getFareCodes(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/fare-codes", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getFareOptions(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/fare-options", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }
  async getDinings(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/dinings", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async holdCabin(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/hold", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async releaseCabin(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/release", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async fetchCabinList({ packageUid, stateroomType }, body) {
    try {
      const response = await this.post(`/v1/b2c/cabins/${packageUid}/${stateroomType}`, body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCodeLists() {
    try {
      const response = await this.get("/v1/b2c/getCodeLists");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async breakDowns(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/break-downs", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async confirmBooking(body) {
    try {
      const response = await this.post("/v1/b2c/cabins/confirm", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getBookingList(body, isOrgLevel = false) {
    try {
      const response = await this.post("/v1/b2c/bookings/lists" + (isOrgLevel ? "?isOrgLevel=true" : ""), body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getBookingItem(bookingUid) {
    try {
      const response = await this.get("/v1/b2c/bookings/details/" + bookingUid);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getB2BBookingItem(bookingUid) {
    try {
      const response = await this.get("/v1/booking/" + bookingUid);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getQuotationTC() {
    try {
      const response = await this.get("/v1/options/tc/quotation");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async saveQuotationTC(body) {
    try {
      const response = await this.post("/v1/options/tc/quotation", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getInvoiceTC() {
    try {
      const response = await this.get("/v1/options/tc/invoice");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async saveInvoiceTC(body) {
    try {
      const response = await this.post("/v1/options/tc/invoice", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getUserById(id) {
    try {
      const response = await this.get("/v1/users/" + id);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getUserIgnore404(id) {
    try {
      const response = await this.get("/v1/users/" + id + "/ignore-404");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getB2BPayment(bookingUid) {
    try {
      const response = await this.get("/v1/payment/" + bookingUid);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async submitB2BPayment(body) {
    try {
      const formData = new FormData();
      Object.keys(body).forEach((key) => {
        if (body[key]) {
          if (Array.isArray(body[key])) formData.append(key, JSON.stringify(body[key]));
          else formData.append(key, body[key]);
        }
      });

      const response = await this.post("/v1/payment", formData);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async updateB2BPaymentItem(body) {
    try {
      const response = await this.update("/v1/payment/item", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async createPayment(body) {
    try {
      const response = await this.update("/v1/b2c/payments/create", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async confirmPayment(body) {
    try {
      const response = await this.post("/v1/payment/confirm", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async cancelPayment(body) {
    try {
      const response = await this.post("/v1/b2c/payments/cancel", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async confirmCancelPayment(body) {
    try {
      const response = await this.post("/v1/payment/confirm-cancel", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async reservationReturnStatus(body) {
    try {
      const response = await this.post("/v1/b2c/reservations/return-status", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getQuotation(body, isOpenNewTab = false) {
    try {
      const response = await this.post("/v1/pdf/quotation", body, { responseType: "arraybuffer" });

      let fileURL = "";

      if (response?.data) {
        const file = new Blob([response.data], { type: "application/pdf" });
        fileURL = URL.createObjectURL(file);
        if (isOpenNewTab) {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `QO_${body.cruiseLineShortcode}${moment(body.sailDate).format("YYMMDD")}_${moment().format(
            "YYMMDD",
          )}_${moment().format("HHmm")}`;
          link.target = "_blank";
          link.click();
          URL.revokeObjectURL(fileURL);

          // window.open(fileURL);
        }
      }

      if (!response?.data || !fileURL) throw new Error("Quotation is not found!");

      return { data: fileURL, error: null };
    } catch (err) {
      if (err.response?.data) {
        err.response.data = JSON.parse(new TextDecoder().decode(err.response.data));
      }

      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getInvoice(body, isOpenNewTab = true) {
    try {
      const response = await this.post("/v1/pdf/invoice", body, { responseType: "arraybuffer" });

      let fileURL = "";

      if (response?.data) {
        const file = new Blob([response.data], { type: "application/pdf" });
        fileURL = URL.createObjectURL(file);
        if (isOpenNewTab) {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `INV_${body.cruiseLineShortcode}${moment(body.sailDate).format("YYMMDD")}_${moment().format(
            "YYMMDD",
          )}_${moment().format("HHmm")}`;
          link.target = "_blank";
          link.click();
          URL.revokeObjectURL(fileURL);

          // window.open(fileURL);
        }
      }

      if (!response?.data || !fileURL) throw new Error("Invoice is not found!");

      return { data: fileURL, error: null };
    } catch (err) {
      if (err.response?.data) {
        err.response.data = JSON.parse(new TextDecoder().decode(err.response.data));
      }

      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getBookingConfirmation(body, isOpenNewTab = true) {
    try {
      const response = await this.post("/v1/pdf/booking-confirmation", body, { responseType: "arraybuffer" });

      let fileURL = "";

      if (response?.data) {
        const file = new Blob([response.data], { type: "application/pdf" });
        fileURL = URL.createObjectURL(file);
        if (isOpenNewTab) {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `BK_${body.cruiseLineShortcode}${moment(body.sailDate).format(
            "YYMMDD",
          )}${body.reservationIDs.map((id) => "_#" + id)}`;
          link.target = "_blank";
          link.click();
          URL.revokeObjectURL(fileURL);

          // window.open(fileURL);
        }
      }

      if (!response?.data || !fileURL) throw new Error("Invoice is not found!");

      return { data: fileURL, error: null };
    } catch (err) {
      if (err.response?.data) {
        err.response.data = JSON.parse(new TextDecoder().decode(err.response.data));
      }

      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAmendmentByBookingUid(booking_uid) {
    try {
      const response = await this.get("/v1/amendment/" + booking_uid);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async createAmendment(body) {
    try {
      const response = await this.post("/v1/amendment", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async recallAmendment(amendment_id) {
    try {
      const response = await this.delete("/v1/amendment/" + amendment_id);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async rejectAmendment(amendment_id, body) {
    try {
      const response = await this.update(`/v1/amendment/${amendment_id}/reject`, body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async approveAmendment(amendment_id, body) {
    try {
      const response = await this.update(`/v1/amendment/${amendment_id}/approve`, body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async reservationChanges(body) {
    try {
      const response = await this.post("/v1/b2c/reservations/confirm-changes", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getAgentCommission() {
    try {
      const response = await this.get("/v1/configuration/AGENT-DEFAULT-COMMISSION");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getLastCommitUSDRate(booking_uid) {
    try {
      const response = await this.get("/v1/pdf/invoice/usd-rate/" + booking_uid + "/last");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getExchangeRateByBookingUid(bookingUid) {
    try {
      const response = await this.get("/v1/pdf/invoice/usd-rate/" + bookingUid);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async sendEmailTemplate(body) {
    try {
      const { templateName, ...payload } = body;
      const response = await this.post(`/v1/mail/${templateName}`, payload);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getB2CExchangeRate(body) {
    try {
      const response = await this.post('/v1/b2c/exchange-rates', body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async sendEmailBookingConfirmationB2C(body) {
    try {
      const response = await this.post('/v1/b2c/send-confirmation-email', body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async updateVpsPrice(body) {
    try {
      const response = await this.post('/v1/b2c/update-vps-price', body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getPortDetail(portCode) {
    try {
      this.setHeader({
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${process.env.VUE_APP_B2C_ACCESS_TOKEN}`,
      });

      const response = await this.post("/v1/b2c/get-port-details/" + portCode);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async putDinings(body) {
    try {
      this.setHeader({
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_B2C_ACCESS_TOKEN}`,
      });

      const response = await this.update("/v1/b2c/cabins/dinings", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async putExtraOptions(body) {
    try {
      this.setHeader({
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_B2C_ACCESS_TOKEN}`,
      });

      const response = await this.update("/v1/b2c/cabins/extra-options", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async putGuestDetails(body) {
    try {
      this.setHeader({
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_B2C_ACCESS_TOKEN}`,
      });

      const response = await this.update("/v1/b2c/cabins/guest-details", body);
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }
}
