import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import router from "@/router";

import B2bProvider from "@/providers/B2bProvider";
import B2cProvider from "@/providers/B2cProvider";
import WpProvider from "@/providers/WpProvider";
const b2bService = new B2bProvider();
const b2cService = new B2cProvider(process.env.VUE_APP_B2C_BASE_URL);
const wpService = new WpProvider(process.env.VUE_APP_WP_BASE_URL);

import { parseCabinData, parseResultItemData } from "./parser";

import Cabin from "../classes/Cabin";
import moment from "moment";
import priceBreakDownHelper from "../helpers/price-breakdown";
import { cruiseLineShortcode } from "../helpers/utils";

// state.search
const SELECT_ALL_SHIPS = "All Ships";
const SELECT_ALL_CRUISE_LINES = "All Cruise Lines";
function getInitialFilters() {
  return {
    minDuration: 0,
    maxDuration: 99,
    maxPrice: 120000,
    portName: null,
    sortBy: "departureDate",
    sortDirection: "ASC",
    sortByValue: "0",
    cruiseLines: [SELECT_ALL_CRUISE_LINES],
    ships: [SELECT_ALL_SHIPS],
    departurePortName: null,
  };
}

function initialBooking() {
  return {
    bookingStepIndex: 0,
    transactionId: "test",
    bookingUid: null,
    bookingStatus: "new",
    package: {
      id: null,
      title: null,
      slug: null,
      cruisingDays: 0,
      cruiseLineCode: "rci",
      cruisePackageCode: "",
      cruiseLineName: "",
      shipName: null,
      shipClass: "quantum_class",
      itineraries: null,
      sailDate: null,
      departurePort: null,
      departurePortCode: null,
    },
    currentCabin: 0,
    cabins: [],
    currency: "THB",
    totalAmount: 0,
    depositAmount: 0,
    priceBreakdown: {
      cruiseFare: 0,
      promotionAmount: 0,
      gratuities: 0,
      taxFee: 0,
    },
    detail: {
      refID: null,
      date: null,
    },
    contactPerson: {
      title: process.env.VUE_APP_NODE_ENV === "development" ? "Mr" : "",
      firstname: process.env.VUE_APP_NODE_ENV === "development" ? "Promptcruise" : "",
      lastname: process.env.VUE_APP_NODE_ENV === "development" ? "TravelElements" : "",
      email: process.env.VUE_APP_NODE_ENV === "development" ? "tanarat@habocruise.com" : "",
      mobileNumber: process.env.VUE_APP_NODE_ENV === "development" ? "0810000000" : "",
      country: process.env.VUE_APP_NODE_ENV === "development" ? "Thailand" : "",
      address: "",
    },
    diningOptions: [],
    isUpdatingCabin: false,
    isUpdatingTraveler: false,
    isUpdatingError: false,
    isHoldCabinError: false,
    usdRate: 0,
    hash: "",
    paymentMethod: "card",
    promotion: {
      affiliator: null,
      campaignCriteria: "PER_BOOKING",
      campaignName: "",
      campaignType: "GIVEAWAY",
      code: "",
      discountAmount: 0,
      discountCap: null,
      discountPercent: 0,
      errorCode: "00",
      errorDescription: "",
      kickbackAmount: 0,
      kickbackPercent: 0,
      packageIds: [],
      promoCodeUsed: 0,
      promotionEligible: "N",
      quantity: null,
      sailDateFrom: null,
      sailDateTo: null,
      shipCodes: [],
      status: "VALID",
      validFrom: null,
      validTo: null,
      calcDiscount: 0,
    },
  };
}

const SESSION_STORAGE_KEY_SEARCH_CRITERIA = "SESSION_STORAGE_KEY_SEARCH_CRITERIA";
const SESSION_STORAGE_KEY_SEARCH_RESULTS = "SESSION_STORAGE_KEY_SEARCH_RESULTS";
const SESSION_STORAGE_KEY_PACKAGE_SELECTED = "SESSION_STORAGE_KEY_PACKAGE_SELECTED";
const SESSION_STORAGE_KEY_BOOKING = "SESSION_STORAGE_KEY_BOOKING";
const SESSION_STORAGE_KEY_FARE_CODES = "SESSION_STORAGE_KEY_FARE_CODES";

export default new Vuex.Store({
  state: {
    currentRoute: null,
    overlay: false,
    user: null,
    bankItems: [
      {
        id: "BKKBTHBK",
        label: "Bangkok Bank",
      },
      {
        id: "AYUDTHBK",
        label: "Bank of Ayudhya",
      },
      {
        id: "KASITHBK",
        label: "Kasikorn Bank",
      },
      {
        id: "BAABTHBK",
        label: "Bank for Agriculture and Agricultural Cooperatives",
      },
      {
        id: "UBOBTHBK",
        label: "CIMB Thai Bank",
      },
      {
        id: "GSBATHBK",
        label: "Government Savings Bank",
      },
      {
        id: "KKPBTHBK",
        label: "Kiatnakin Phatra Bank",
      },
      {
        id: "KRTHTHBK",
        label: "Krung Thai Bank",
      },
      {
        id: "LAHRTHB2",
        label: "Land & House Bank",
      },
      {
        id: "SICOTHBK",
        label: "Siam Commercial Bank",
      },
      {
        id: "SCBLTHBX",
        label: "Standard Chartered Bank",
      },
      {
        id: "TFPCTHB1",
        label: "Tisco Bank",
      },
      {
        id: "TMBKTHBK",
        label: "TMB Thanachart Bank",
      },
      {
        id: "UOVBTHBK",
        label: "UOB",
      },
    ],
    searchCriteria: null,
    // {
    //   monthSelected: ["2022-10"],
    //   destinationSelected: ["All Destination"],
    //   departurePort: "",
    //   duration: [2, 12],
    //   price: 120000,
    //   cruiseLineCodes: [""],
    //   shipCodes: [""],
    //   adults: 1,
    //   children: 0,
    //   isSenior: false,
    //   isAccessibleRoom: false,
    // },
    searchResults: [],
    packageSelected: null,
    search: {
      criteria: {
        fromDate: "2021-01-01",
        toDate: "2021-02-01",
        cruisingZones: ["All Destination"],
        selectedMonth: [],
      },
      destinationOptions: [
        {
          key: "All Destination",
          title: "ALL DESTINATION",
          detail: null,
        },
        {
          key: "Asia",
          title: "ASIA",
          detail: "Japan, China, Southeast Asia, etc.",
        },
        {
          key: "Alaska & North America",
          title: "ALASKA",
          detail: "Vancouver, Juneau, Seattle, etc.",
        },
        {
          key: "Mediterranean",
          title: "MEDITERRANEAN",
          detail: "Spain, France, Italy, Greece, etc.",
        },
        {
          key: "North Europe",
          title: "NORTH EUROPE",
          detail: "England, Denmark, Finland, Iceland, etc.",
        },
        {
          key: "Australia & New Zealand",
          title: "AUSTRALIA<br>NEW ZEALAND",
          detail: null,
        },
        {
          key: "Caribbean",
          title: "CARIBBEAN",
          detail: null,
        },
        {
          key: "Others",
          title: "OTHERS",
          detail: "South America, Transpacific, etc.",
        },
      ],
      results: [],
      ports: [],
      cruiseLines: [SELECT_ALL_CRUISE_LINES],
      ships: [SELECT_ALL_SHIPS],
      departurePorts: [],
      filteredShips: [],
      packageDates: [],
      resultShowing: false,
      filters: getInitialFilters(),
      title: null,
      selectedPackage: null,
      transactionId: null,

      tags: [],
      heroImages: [],
      description: null,
      price: 0,
      currency: null,

      meta: {
        days: 0,
        nights: 0,
        duration: 0,
        locations: [],
        numberOfParticipants: 0,
      },
    },
    booking: initialBooking(),
    fareCodes: [],
  },
  mutations: {
    setCurrentRoute(state, value) {
      state.currentRoute = value;
    },
    setOverlay(state, value) {
      state.overlay = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setSearchCriteria(state, value) {
      state.searchCriteria = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_SEARCH_CRITERIA, JSON.stringify(value));
    },
    setSearchCriteriaWithKey(state, { key, value }) {
      if (state.searchCriteria && Object.keys(state.searchCriteria).includes(key)) {
        state.searchCriteria[key] = value;
        sessionStorage.setItem(SESSION_STORAGE_KEY_SEARCH_CRITERIA, JSON.stringify(state.searchCriteria));
      }
    },
    clearSearchCriteria(state) {
      state.searchCriteria = null;
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SEARCH_CRITERIA);
    },
    setSearchResults(state, value) {
      state.searchResults = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_SEARCH_RESULTS, JSON.stringify(value));
    },
    clearSearchResults(state) {
      state.searchResults = [];
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SEARCH_RESULTS);
    },
    clearBookingListCriteria(state) {
      sessionStorage.removeItem("SESSION_STORAGE_KEY_BOOKING_LIST_FILTER");
      sessionStorage.removeItem("SESSION_STORAGE_KEY_BOOKING_LIST_PAGINATION");
      sessionStorage.removeItem("SESSION_STORAGE_KEY_BOOKING_LIST_CURRENT_PAGE");
    },
    setPackageSelected(state, value) {
      state.packageSelected = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_PACKAGE_SELECTED, JSON.stringify(value));
    },
    setBooking(state, value) {
      state.booking = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_BOOKING, JSON.stringify(value));
    },
    setBookingPackage(state, value) {
      state.booking.package = value;
    },
    clearBooking(state) {
      state.booking = initialBooking();
      sessionStorage.removeItem(SESSION_STORAGE_KEY_BOOKING);
    },
    clearBookingAndPackage(state) {
      state.booking = initialBooking();
      state.packageSelected = null;
      sessionStorage.removeItem(SESSION_STORAGE_KEY_BOOKING);
      sessionStorage.removeItem(SESSION_STORAGE_KEY_PACKAGE_SELECTED);
    },
    updateBooking(state, { key, value }) {
      state.booking[key] = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_BOOKING, JSON.stringify(state.booking));
    },
    updateCabin(state, { cabinIndex, key, value }) {
      const cabin = state.booking.cabins[cabinIndex];
      cabin[key] = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_BOOKING, JSON.stringify(state.booking));
    },
    updateTraveler(state, { cabinIndex, travelerIndex, key, value }) {
      const traveler = state.booking.cabins[cabinIndex].travelers[travelerIndex];
      traveler[key] = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_BOOKING, JSON.stringify(state.booking));
    },
    clearSelectedCabin(state, { cabinIndex }) {
      state.booking.cabins[cabinIndex].selectedCabin = {
        cabinNo: "",
        cabinGrade: "",
        deckNo: "",
        releaseTime: "",
        totalPrice: 0,
        positionInShip: "",
        maxOccupancy: 0,
        fareCode: "BESTRATE",
        fareDetail: null,
        cf: 0,
        ca: 0,
        cp: 0,
        gr: 0,
        pr: 0,
        fe: 0,
      };

      var totalAmount = 0;
      state.booking.cabins.forEach((cabin) => {
        totalAmount += cabin.selectedCabin.totalPrice == null ? 0 : cabin.selectedCabin.totalPrice;
      });
      state.booking.totalAmount = totalAmount;
    },
    setFareCodes(state, value) {
      state.fareCodes = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY_FARE_CODES, JSON.stringify(value));
    },
  },
  actions: {
    AddOrChangeCabinType({ state }, { cabinIndex, cabinType, cabinTitle }) {
      if (state.booking.cabins[cabinIndex]) {
        state.booking.cabins[cabinIndex].id = cabinIndex;
        state.booking.cabins[cabinIndex].type = cabinType;
        state.booking.cabins[cabinIndex].title = cabinTitle;
      } else {
        const newCabin = new Cabin();
        newCabin.id = cabinIndex;
        newCabin.type = cabinType;
        newCabin.title = cabinTitle;
        state.booking.cabins.push(newCabin);
      }
      state.booking.currentCabin = cabinIndex;
    },
    BookingSelectCategory({ commit, state }, { cabinIndex, categoryCode }) {
      const selectedCategory = state.booking.cabins[cabinIndex].availableCategories.find(
        (item) => item.cabinGrade === categoryCode,
      );
      var selectedDeck;
      var showingDecks = state.booking.cabins[cabinIndex].decks;
      var showingCabins = state.booking.cabins[cabinIndex].availableCabins;
      if (selectedCategory) {
        selectedDeck = state.booking.cabins[cabinIndex].decks.find(
          (item) => item.deckNo === (selectedCategory.decks[0] ?? state.booking.cabins[cabinIndex].decks[0]),
        );
        showingDecks = state.booking.cabins[cabinIndex].decks.filter((deck) =>
          selectedCategory.decks.includes(deck.deckNo),
        );
        showingCabins = state.booking.cabins[cabinIndex].availableCabins.filter(
          (cabin) => cabin.cabinGrade === categoryCode,
        );
        if (!showingCabins.find((cabin) => cabin.cabinNo === state.booking.cabins[cabinIndex].selectedCabin.cabinNo)) {
          commit("clearSelectedCabin", {
            cabinIndex,
          });
        }
      } else {
        // categoryCode === "all", "gty"
        selectedDeck = state.booking.cabins[cabinIndex].decks[0];
      }
      if (selectedDeck) {
        commit("updateCabin", {
          cabinIndex,
          key: "categoryFilter",
          value: categoryCode,
        });
        commit("updateCabin", {
          cabinIndex,
          key: "showingDecks",
          value: showingDecks,
        });
        commit("updateCabin", {
          cabinIndex,
          key: "showingCabins",
          value: showingCabins,
        });
        commit("updateCabin", {
          cabinIndex,
          key: "selectedDeck",
          value: selectedDeck,
        });
        commit("updateCabin", {
          cabinIndex,
          key: "isUpdatingDeck",
          value: false,
        });
        commit("updateCabin", {
          cabinIndex,
          key: "isUpdatingDeck",
          value: true,
        });
      }
    },
    BookingSelectCabin({ commit, state }, { cabinIndex, cabinNo }) {
      const selectedCabin = state.booking.cabins[cabinIndex].availableCabins.find((item) => item.cabinNo == cabinNo);

      if (!selectedCabin) return { error: `cabinNo:: ${cabinNo} is not available.` };

      commit("updateCabin", {
        cabinIndex,
        key: "selectedCabin",
        value: selectedCabin,
      });
      var totalAmount = 0;
      state.booking.cabins.forEach((cabin) => {
        totalAmount += cabin.selectedCabin.totalPrice == null ? 0 : cabin.selectedCabin.totalPrice;
      });
      commit("updateBooking", {
        key: "totalAmount",
        value: totalAmount,
      });

      return { error: null };
    },
    async PushLog({ dispatch }, { event, payload }) {
      // access token
      const { data, error } = await b2bService.log(event, payload);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("PushLog", { event, payload });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async Refresh({ commit }) {
      // refresh token
      const { data, error } = await b2bService.refresh();
      if (error) {
        commit("setUser", null);
        return { data: null, error: "Refresh token was expired" };
      }

      commit("setUser", data.data);

      return { data, error: null };
    },
    async ValidateAccessToken({ dispatch }) {
      // access token
      const { data, error } = await b2bService.validateAccessToken();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ValidateAccessToken");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async ValidateSignupToken({}, { token }) {
      // public
      const { data, error } = await b2bService.validateSignupToken(token);
      if (error) {
        return { data: null, error: "Invalid credentials" };
      }

      return { data, error: null };
    },
    async ValidateResetPasswordToken({}, { token }) {
      // public
      const { data, error } = await b2bService.validateResetPasswordToken(token);
      if (error) {
        return { data: null, error: "Invalid credentials" };
      }

      return { data, error: null };
    },
    async Login({ commit }, { email, password }) {
      // public
      const { data, error } = await b2bService.login({ email, password });
      if (error || data?.status?.code !== 200 || !data.data) return { data: null, error };

      const user = data.data;
      commit("setUser", user);

      router.push({ name: "dashboard" });

      return { data, error: null };
    },
    async Logout({ commit, dispatch }) {
      await dispatch("ReleaseAllCabins");

      // refresh token
      const { data, error } = await b2bService.logout();
      if (error || data?.status?.code !== 200) return { data: null, error };

      commit("setUser", null);

      router.push({ name: "login" });

      return { data, error: null };
    },
    async ForgotPassword({}, { email }) {
      // public
      const { data, error } = await b2bService.forgotPassword({ email });
      if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async ResetPassword({}, { new_password, confirm_new_password, token }) {
      // public
      const { data, error } = await b2bService.resetPassword({ new_password, confirm_new_password, token });
      if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async ChangePassword({ dispatch }, { current_password, new_password, confirm_new_password }) {
      // access token
      const { data, error } = await b2bService.changePassword({ current_password, new_password, confirm_new_password });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ChangePassword", { current_password, new_password, confirm_new_password });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async Register(
      {},
      {
        company_name,
        company_address,
        company_country,
        tax_id,
        contact_person_title,
        contact_person_firstname,
        contact_person_lastname,
        tel,
        tel_country_code,
        bank_name,
        account_number,
        account_name,
        email,
        password,
        confirm_password,
        company_logo_image,
        promptpay_qr_image,
        token,
      },
    ) {
      // public
      const { data, error } = await b2bService.register({
        company_name,
        company_address,
        company_country,
        tax_id,
        contact_person_title,
        contact_person_firstname,
        contact_person_lastname,
        tel,
        tel_country_code,
        bank_name,
        account_number,
        account_name,
        email,
        password,
        confirm_password,
        company_logo_image,
        promptpay_qr_image,
        token,
      });
      if (error || data?.status?.code !== 200 || !data.data) return { data: null, error };

      router.push({ name: "login" });

      return { data, error: null };
    },
    async StaffRegister(
      {},
      {
        contact_person_title,
        contact_person_firstname,
        contact_person_lastname,
        tel,
        tel_country_code,
        password,
        confirm_password,
        token,
      },
    ) {
      // public
      const { data, error } = await b2bService.staffRegister({
        contact_person_title,
        contact_person_firstname,
        contact_person_lastname,
        tel,
        tel_country_code,
        password,
        confirm_password,
        token,
      });
      if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetAdminInvitee({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getAdminInvitee();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAdminInvitee");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async SendAdminInvitee({ dispatch }, { email }) {
      // access token
      const { data, error } = await b2bService.sendAdminInvitee({ email });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SendAdminInvitee", { email });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async SendMakerInvitee({ dispatch }, { email, organizationId }) {
      // access token
      const { data, error } = await b2bService.sendMakerInvitee({ email, organizationId });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SendMakerInvitee", { email, organizationId });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async RemoveInviteeById({ dispatch }, { id, forceDeleteUser }) {
      // access token
      const { data, error } = await b2bService.removeInviteeById(id, forceDeleteUser);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("RemoveInviteeById", { id, forceDeleteUser });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async RemoveInviteeByEmail({ dispatch }, { email, forceDeleteUser }) {
      // access token
      const { data, error } = await b2bService.removeInviteeByEmail(email, forceDeleteUser);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("RemoveInviteeByEmail", { email, forceDeleteUser });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetAgentAdmin({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getAgentAdmin();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAgentAdmin");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetAgentByOrganizationId({ dispatch }, { organizationId }) {
      // access token
      const { data, error } = await b2bService.getAgentByOrganizationId(organizationId);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAgentByOrganizationId", { organizationId });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetAgentMakerByOrganizationId({ dispatch }, { organizationId }) {
      // access token
      const { data, error } = await b2bService.getAgentMakerByOrganizationId(organizationId);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAgentMakerByOrganizationId", { organizationId });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetCompany({ dispatch }, { organizationId }) {
      // access token
      const { data, error } = await b2bService.getCompany(organizationId);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetCompany", { organizationId });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async GetCompanyIgnore404({ dispatch }, { organizationId }) {
      // access token
      const { data, error } = await b2bService.getCompanyIgnore404(organizationId);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetCompanyIgnore404", { organizationId });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async UpdateCompany({ dispatch }, { organizationId, body }) {
      // access token
      const { data, error } = await b2bService.updateCompany(organizationId, body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("UpdateCompany", { organizationId, body });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async UpdateUser({ dispatch }, { userId, body }) {
      // access token
      const { data, error } = await b2bService.updateUser(userId, body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("UpdateUser", { userId, body });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async RejectAgentAdmin({ dispatch }, { email }) {
      // access token
      const { data, error } = await b2bService.rejectAgentAdmin({ email });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("RejectAgentAdmin", { email });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error: null };
    },
    async ApproveAgentAdmin({ dispatch }, { email }) {
      // access token
      const { data, error } = await b2bService.approveAgentAdmin({ email });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ApproveAgentAdmin", { email });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetWpPopularCruising() {
      return wpService.getWpPopularCruising();
    },
    async GetWpMedia({}, { id }) {
      return wpService.getWpMedia(id);
    },
    async GetSearchOptions({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getSearchOptions();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetSearchOptions");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SearchCruise({ state, commit, dispatch }, criteria) {
      commit("setOverlay", true);

      // access token
      const { data, error } = await b2bService.getCruiseItineraries({
        ...criteria,
        accessible: criteria.accessible ? "Y" : "N",
      });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          commit("setOverlay", false);
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SearchCruise", criteria);
      } else if (error || data?.status?.code !== 200) {
        commit("setOverlay", false);
        return { data: null, error };
      }

      let searchResults = data.data.data
        .map((result) => parseResultItemData(result))
        .filter((item) => item.shipName != "");
      const exemptPorts = ["At Sea", "Cruising"];
      var portGrouping = function (cruisePackages) {
        return cruisePackages.reduce(function (storage, cruisePackage) {
          cruisePackage.itineraries.forEach((item) => {
            if (!exemptPorts.includes(item.portName))
              (storage[item.portName] = storage[item.portName] || []).push(cruisePackage.id);
          });
          return storage;
        }, {});
      };
      state.search.ports = portGrouping(searchResults);
      var departurePortGrouping = function (cruisePackages) {
        return cruisePackages.reduce(function (storage, cruisePackage) {
          (storage[cruisePackage.departurePort] = storage[cruisePackage.departurePort] || []).push(cruisePackage.id);
          return storage;
        }, {});
      };
      state.search.departurePorts = departurePortGrouping(searchResults);
      var packageGrouping = function (cruisePackages) {
        return cruisePackages.reduce(function (storage, cruisePackage) {
          (storage[cruisePackage.cruisePackageCode] = storage[cruisePackage.cruisePackageCode] || []).push({
            id: cruisePackage.id,
            selectedId: cruisePackage.selectedId,
            cruisingDate: cruisePackage.cruisingDate,
            sailDate: cruisePackage.sailDate,
            sailYear: cruisePackage.sailDate.substring(0, 4),
            sailDay: cruisePackage.cruisingDate.substring(0, 3) + " " + cruisePackage.sailDate.substring(8, 10),
            startingPrice: cruisePackage.minPrice,
            itineraries: cruisePackage.itineraries,
            roomTypePrice: cruisePackage.roomTypePrice,
          });
          return storage;
        }, {});
      };
      state.search.packageDates = packageGrouping(searchResults);
      var ships = function (cruisePackages) {
        return cruisePackages.reduce(function (storage, cruisePackage) {
          if (Object.keys(storage).length === 0) {
            storage = [SELECT_ALL_SHIPS];
          }
          if (!storage.includes(cruisePackage.shipName)) storage.push(cruisePackage.shipName);
          return storage;
        }, {});
      };
      state.search.ships = ships(searchResults);
      var cruiseLines = function (cruisePackages) {
        return cruisePackages.reduce(function (storage, cruisePackage) {
          if (Object.keys(storage).length === 0) {
            storage = [SELECT_ALL_CRUISE_LINES];
          }
          if (!storage.includes(cruisePackage.cruiseLineName)) {
            storage.push(cruisePackage.cruiseLineName);
          }
          return storage;
        }, {});
      };
      state.search.cruiseLines = cruiseLines(searchResults);
      if (state.search.filters.cruiseLines.length === 0) state.search.filters.cruiseLines = state.search.cruiseLines;
      if (state.search.filters.ships.length === 0) state.search.filters.ships = state.search.ships;
      // var stateResults = [...new Map(searchResults.map((item) => [item["cruisePackageCode"], item])).values()];
      var stateResults = [...searchResults];
      // var oldResults = searchResults;
      // stateResults.forEach((item) => {
      //   var targetResults = oldResults
      //     .filter((oldResult) => item.cruisePackageCode === oldResult.cruisePackageCode)
      //     .sort(function (a, b) {
      //       return a.minPrice - b.minPrice;
      //     });
      //   item.minPrice = targetResults[0].minPrice;
      //   item.minPriceId = targetResults[0].minPriceId;
      //   item.maxPrice = targetResults[targetResults.length - 1].maxPrice;
      //   item.maxPriceId = targetResults[targetResults.length - 1].maxPriceId;

      //   if (state.search.filters.sortDirection === "ASC") {
      //     item.selectedPrice = targetResults[0].minPrice;
      //     item.selectedId = targetResults[0].minPriceId;
      //     item.roomTypePrice = targetResults[0].roomTypePrice;
      //   } else {
      //     item.selectedPrice = targetResults[targetResults.length - 1].maxPrice;
      //     item.selectedId = targetResults[targetResults.length - 1].maxPriceId;
      //     item.roomTypePrice = targetResults[targetResults.length - 1].roomTypePrice;
      //   }
      //   targetResults = oldResults
      //     .filter((oldResult) => item.cruisePackageCode === oldResult.cruisePackageCode)
      //     .sort(function (a, b) {
      //       return a.minDate - b.minDate;
      //     });

      //   item.minDate = targetResults[0].minDate;
      //   item.minCruisingDate = targetResults[0].minCruisingDate;
      //   item.minDateId = targetResults[0].minDateId;
      //   targetResults = oldResults
      //     .filter((oldResult) => item.cruisePackageCode === oldResult.cruisePackageCode)
      //     .sort(function (a, b) {
      //       return a.maxDate - b.maxDate;
      //     });
      //   item.maxDate = targetResults[targetResults.length - 1].maxDate;
      //   item.maxCruisingDate = targetResults[0].maxCruisingDate;
      //   item.maxDateId = targetResults[targetResults.length - 1].maxDateId;
      //   if (state.search.filters.sortDirection === "ASC") {
      //     item.selectedDate = targetResults[0].minDate;
      //     item.selectedId = targetResults[0].minDateId;
      //     item.date = targetResults[0].minDate;
      //     item.sailDate = targetResults[0].minDate;
      //     item.cruisingDate = targetResults[0].minCruisingDate;
      //     item.price = targetResults[0].price;
      //     item.roomTypePrice = targetResults[0].roomTypePrice;
      //     item.itineraries = targetResults[0].itineraries;
      //   } else {
      //     item.selectedDate = targetResults[targetResults.length - 1].maxDate;
      //     item.selectedId = targetResults[targetResults.length - 1].maxDateId;
      //     item.date = targetResults[targetResults.length - 1].maxDate;
      //     item.sailDate = targetResults[targetResults.length - 1].maxDate;
      //     item.cruisingDate = targetResults[targetResults.length - 1].maxCruisingDate;
      //     item.price = targetResults[targetResults.length - 1].price;
      //     item.roomTypePrice = targetResults[targetResults.length - 1].roomTypePrice;
      //     item.itineraries = targetResults[targetResults.length - 1].itineraries;
      //   }
      // });
      commit("setSearchResults", stateResults);
      commit("setOverlay", false);

      return { data, error };
    },
    async GetCabins({ commit, state, dispatch }, body) {
      commit("setOverlay", true);

      const request = {
        ...body,
        accessible: body.accessible ? "Y" : "N",
        senior: body.senior ? "Y" : "N",
      };

      // access token
      const { data, error } = await b2bService.getCabins(request);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          commit("setOverlay", false);
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetCabins", body);
      } else if (error || data?.status?.code !== 200) {
        commit("setOverlay", false);
        return { data: null, error };
      } else if (!Array.isArray(data.data.data) || (Array.isArray(data.data.data) && data.data.data.length === 0)) {
        commit("setOverlay", false);
        return { data: null, error: "No available cabins!" };
      }

      const cabinData = parseCabinData(data.data.data);
      const stateCabin = state.booking.cabins[state.booking.currentCabin];

      stateCabin.adults = body.noOfAdults;
      stateCabin.children = body.noOfChildren;
      stateCabin.senior = body.senior;
      stateCabin.accessible = body.accessible;

      stateCabin.decks = cabinData.decks;
      stateCabin.availableCabins = cabinData.availableCabins;
      stateCabin.selectedDeck = cabinData.decks[0] ?? {
        deckNo: "",
        deckName: "",
        url: "",
        deckPlanCode: "",
      };
      stateCabin.isUpdatingDeck = true;
      stateCabin.ppgr = cabinData.ppgr;

      stateCabin.gtyCode = cabinData.gtyCode;
      stateCabin.gtyFareCode = cabinData.gtyFareCode;
      stateCabin.guaranteedRoomPrice = cabinData.gtyPrice;
      stateCabin.gtyOptions = cabinData.gtyOptions;

      stateCabin.normalRoomPrice = cabinData.normalPrice;
      stateCabin.availableCategories = cabinData.availableCategories;
      stateCabin.maxRoomPrice = cabinData.maxPrice;
      stateCabin.showingDecks = cabinData.decks;
      stateCabin.showingCabins = cabinData.availableCabins;
      var totalAmount = 0;
      state.booking.cabins.forEach((cabin) => {
        totalAmount += cabin.selectedCabin == null ? 0 : cabin.selectedCabin.totalPrice;
      });
      state.booking.totalAmount = totalAmount;
      state.booking.deckPlanCode = cabinData.deckPlanCode;
      state.booking.isUpdatingError = false;

      commit("setOverlay", false);

      return { data, error: null };
    },
    async GetFareCodes({ commit, dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getFareCodes(body);
      if (data?.data?.data) commit("setFareCodes", data.data.data);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetFareCodes", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetFareOptions({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getFareOptions({
        ...body,
        senior: body.senior ? "Y" : "N",
      });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetFareOptions", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetDinings({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getDinings(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetDinings", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async HoldCabin({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.holdCabin(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("HoldCabin", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ReleaseCabin({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.releaseCabin(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ReleaseCabin", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ReleaseAllCabins({ dispatch, commit, state }) {
      const releaseCabinRequest = (cabin) =>
        new Promise(async (resolve, reject) => {
          if (!cabin.adults && !cabin.children) reject("adults := 0 and kids := 0");

          const { data, error } = await dispatch("ReleaseCabin", {
            packageUid: state.packageSelected.packageUid,
            categoryCode: cabin.selectedCabin.cabinGrade,
            cabinNo: cabin.selectedCabin.cabinNo,
            noOfAdults: cabin.adults,
            noOfChildren: cabin.children,
          });

          if (error) reject(error);
          else resolve(data?.data);
        });

      commit("setOverlay", true);

      const releaseCabinResults = await Promise.all(
        state.booking.cabins
          .filter((cabin) => cabin.selectedCabin?.cabinNo)
          .map((cabin) =>
            releaseCabinRequest(cabin)
              .then((response) => ({ response, isSuccess: true }))
              .catch((response) => ({ response, isSuccess: false })),
          ),
      );

      commit("setOverlay", false);
    },
    async GetCodeLists({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getCodeLists(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetCodeLists", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async BreakDowns({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.breakDowns(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("BreakDowns", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ConfirmBooking({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.confirmBooking(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ConfirmBooking", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetQuotationTC({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getQuotationTC();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetQuotationTC");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SaveQuotationTC({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.saveQuotationTC(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SaveQuotationTC", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetInvoiceTC({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getInvoiceTC();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetInvoiceTC");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SaveInvoiceTC({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.saveInvoiceTC(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SaveInvoiceTC", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetBookingListOrgAdmin({ dispatch }, { body, isOrgLevel }) {
      // access token
      const { data, error } = await b2bService.getBookingList(body, isOrgLevel);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetBookingListOrgAdmin", { body, isOrgLevel });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetBookingList({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getBookingList(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetBookingList", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetBookingItem({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getBookingItem(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetBookingItem", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetB2BBookingItem({ dispatch }, bookingUid) {
      // access token
      const { data, error } = await b2bService.getB2BBookingItem(bookingUid);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetB2BBookingItem", bookingUid);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetUserById({ dispatch }, id) {
      // access token
      const { data, error } = await b2bService.getUserById(id);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetUserById", id);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetUserIgnore404({ dispatch }, id) {
      // access token
      const { data, error } = await b2bService.getUserIgnore404(id);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetUserIgnore404", id);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetB2BPayment({ dispatch }, bookingUid) {
      // access token
      const { data, error } = await b2bService.getB2BPayment(bookingUid);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetB2BPayment", bookingUid);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SubmitB2BPayment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.submitB2BPayment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SubmitB2BPayment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async CreatePayment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.createPayment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("CreatePayment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ConfirmPayment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.confirmPayment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ConfirmPayment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async UpdateB2BPaymentItem({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.updateB2BPaymentItem(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("UpdateB2BPaymentItem", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async CancelPayment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.cancelPayment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("CancelPayment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ConfirmCancelPayment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.confirmCancelPayment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ConfirmCancelPayment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ReservationReturnStatus({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.reservationReturnStatus(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ReservationReturnStatus", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetQuotation({ state, dispatch }, { remark, tc, isOpenNewTab = true }) {
      let body = {
        companyLogoUrl: state.user.organization.company_logo_image,
        cruiseLogoUrl: `https://images.habocruise.com/logos/${state.booking.package.cruiseLogo}`,
        itineraries: state.booking.package.itineraries.map((each) => {
          return {
            ...each,
            date: moment(each.date).format("ddd DD MMM YYYY"),
          };
        }),
        cabins: state.booking.cabins.map((cabin) => {
          const priceBreakDown = priceBreakDownHelper(cabin);
          return {
            info: {
              sailDate: state.booking.package?.sailDate,
              cruisingDays: state.booking.package?.cruisingDays,
              fareType: cabin.selectedCabin?.fareDetail?.fareType ?? "RD",
              deposit:
                cabin.selectedCabin?.fareDetail?.paymentSchedule.find((each) => each.paymentNo === 1)?.amount ?? 350,
            },
            cruiseLine: state.booking.package.cruiseLineName,
            ship: state.booking.package.shipName,
            sailDate: state.booking.package.sailDate,
            duration: state.booking.package.cruisingDays,
            itinerary: state.booking.package.title,
            category: cabin.selectedCabin.cabinGrade + " - " + cabin.selectedCabin.description,
            cabinNo: cabin.selectedCabin.cabinNo,
            guestAmount: `${cabin.adults} Adults ${cabin.children} Kid${cabin.senior ? ", Senior included" : ""}`,
            // extras: priceBreakDown.promotion.element.map(each => each.detail),
            extra: cabin.selectedCabin?.fareDetail?.fareDetail?.promotionDescription,
            quoteDate: moment().format("DD MMM YYYY at HH:mm"),
            validTill: moment().add(15, "minutes").format("DD MMM YYYY at HH:mm"),
            companyName: state.user.organization.name,
            tel: state.user.tel,
            email: state.user.email,
            remark,
            priceBreakDown,
            paymentSchedule: cabin.selectedCabin.fareDetail.paymentSchedule.map((each) => {
              let dueDate = moment(each.dueDate).subtract(2, "days");
              if (dueDate.diff(moment(), "days") < 0) dueDate = moment();

              // adjust correct balance
              if (each.paymentNo === 2) {
                const depositTrx = cabin.selectedCabin.fareDetail.paymentSchedule.find(
                  (payment) => payment.paymentNo === 1,
                );
                each.amount -= depositTrx?.amount || 0;
              }

              return {
                ...each,
                dueDate: dueDate.format("DD MMM YYYY"),
                term: (() => {
                  if (each.paymentNo === 1) return "Deposit";
                  if (each.paymentNo === 2) return "Balance";
                  if (each.paymentNo === 3) return "Full Payment";
                })(),
              };
            }),
          };
        }),
        tc,
        cruiseLineShortcode: cruiseLineShortcode(state.booking.package?.cruiseLineShortcode),
        sailDate: state.booking.package?.sailDate,
      };

      // access token
      const { data, error } = await b2bService.getQuotation(body, isOpenNewTab);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetQuotation", { remark, tc, isOpenNewTab });
      } else if (error) return { data: null, error };

      return { data, error };
    },
    async GetInvoice({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getInvoice(body, true);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetInvoice", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetBookingConfirmation({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.getBookingConfirmation(body, true);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetBookingConfirmation", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetAmendmentByBookingUid({ dispatch }, booking_uid) {
      // access token
      const { data, error } = await b2bService.getAmendmentByBookingUid(booking_uid);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAmendmentByBookingUid", booking_uid);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async CreateAmendment({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.createAmendment(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("CreateAmendment", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async RecallAmendment({ dispatch }, amendment_id) {
      // access token
      const { data, error } = await b2bService.recallAmendment(amendment_id);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("RecallAmendment", amendment_id);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async RejectAmendment({ dispatch }, { amendment_id, comment }) {
      // access token
      const { data, error } = await b2bService.rejectAmendment(amendment_id, { comment });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("RejectAmendment", { amendment_id, comment });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ApproveAmendment({ dispatch }, { amendment_id, comment }) {
      // access token
      const { data, error } = await b2bService.approveAmendment(amendment_id, { comment });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ApproveAmendment", { amendment_id, comment });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async ReservationChanges({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.reservationChanges(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("ReservationChanges", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetAgentCommission({ dispatch }) {
      // access token
      const { data, error } = await b2bService.getAgentCommission();
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetAgentCommission");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetLastCommitUSDRate({ dispatch }, booking_uid) {
      // access token
      const { data, error } = await b2bService.getLastCommitUSDRate(booking_uid);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetLastCommitUSDRate", booking_uid);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetExchangeRate({ dispatch }, booking_uid) {
      // access token
      const { data, error } = await b2bService.getExchangeRateByBookingUid(booking_uid);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetExchangeRate", booking_uid);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SendEmailTemplate({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.sendEmailTemplate(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SendEmailTemplate", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetLocalExchangeRate({ dispatch, state }) {
      const localCurrency = state.user?.local_currency || "USD";
      if (localCurrency === "USD") return { data: { label: `${localCurrency}/USD`, value: 1.0 }, error };

      // access token
      const { data, error } = await b2bService.getB2CExchangeRate({
        currencies: [localCurrency],
      });

      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetLocalExchangeRate");
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data: { label: `${localCurrency}/USD`, value: data?.data[localCurrency] }, error };
    },
    async GetB2CExchangeRate({ dispatch }, currencies) {
      // access token
      const { data, error } = await b2bService.getB2CExchangeRate({ currencies });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetB2CExchangeRate", currencies);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async SendEmailBookingConfirmationB2C({ dispatch }, { bookingUid }) {
      // access token
      const { data, error } = await b2bService.sendEmailBookingConfirmationB2C({ bookingUid });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("SendEmailBookingConfirmationB2C", { bookingUid });
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async UpdateVpsPrice({ dispatch }, criteria) {
      // access token
      const { data, error } = await b2bService.updateVpsPrice({
        ...criteria,
        senior: criteria.senior ? "Y" : "N",
        accessible: criteria.accessible ? "Y" : "N",
      });
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("UpdateVpsPrice", criteria);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async GetPortDetail({ dispatch }, portCode) {
      // access token
      const { data, error } = await b2bService.getPortDetail(portCode);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("GetPortDetail", portCode);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async PutDinings({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.putDinings(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("PutDinings", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async PutExtraOptions({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.putExtraOptions(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("PutExtraOptions", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
    async PutGuestDetails({ dispatch }, body) {
      // access token
      const { data, error } = await b2bService.putGuestDetails(body);
      if (error?.status?.code === 401) {
        const { data: refreshData, error: refreshError } = await dispatch("Refresh");
        if (refreshError) {
          return { data: null, error: "Invalid credentials" };
        }
        return dispatch("PutGuestDetails", body);
      } else if (error || data?.status?.code !== 200) return { data: null, error };

      return { data, error };
    },
  },
  modules: {},
  getters: {
    currentRoute: (state) => state.currentRoute,
    overlay: (state) => state.overlay,
    isAuthenticated: (state) => !!state.user,
    user: (state) => state.user || {},
    bankItems: (state) => state.bankItems || [],
    lessUserEmail: (state) => {
      const n = 13;
      const email = state.user?.email ? state.user.email : "";

      return email.length > n ? `${email.substr(0, n - 1)}...` : email;
    },
    lessUserCompanyName: (state) => {
      const n = 13;
      const companyName = state.user?.company_name ? state.user.company_name : "";

      return companyName.length > n ? `${companyName.substr(0, n - 1)}...` : companyName;
    },
    searchCriteria: (state) => {
      return state.searchCriteria;
    },
    searchResults: (state) => state.searchResults,
    packageSelected: (state) => state.packageSelected,
    booking: (state) => state.booking,
    fareCodes: (state) => state.fareCodes,
    countryCodeList_1GuestOver21: (state) => ["CAN", "USA", "PRI"],
  },
});
